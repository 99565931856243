import React, {} from 'react'
import {Skeleton} from 'antd'
import style from './index.module.scss'

const ContainerBox = (props) => {
  const {title='',loading=true, id=""} = props
  return <div className={style.containerBox_container}>
    <div className={style.containerBox_wrapper} id={id}>
      <Skeleton loading={loading}>
        { title
            ?<div className={style.containerBox_title} style={title == '约 4500 家样本企业潜在主体信用质量分布' ? {marginBottom:'1px'} :{}}>
              <div>{title}</div>
            </div>
            :null
        }
        {props.children}
      </Skeleton>
    </div>
  </div>
}

export default ContainerBox