import React from "react";
import style from "./index.module.scss";
import ReactECharts from "echarts-for-react";
import { Spin } from 'antd'
import arrowRight from '@/assets/icons/arrowright.png';
const ChartAnalysis = (props) => {
  const { data = {},options = {} } = props;
  return (
    <div className={style.chartAnalysis_container}>
      <div className={style.chartAnalysis_wrapper}>
        <div className={style.chartAnalysis_title}>
          <div className={style.chartAnalysis_title_main}>{props.flowName}</div>
          {
            props.chooseZb ? <div className={style.chartAnalysis_title_link} onClick={() => {
              props.changeCurrentSelectId(null,null,undefined,undefined,undefined)
            }}>所有指标 <img src={arrowRight} style={{marginLeft:"8px"}} width={14} height={14}/></div> : null
          }
         
        </div>
        <div style={{ width: "100%", height: "235px" }}>
          <Spin spinning={props.flowLoading}>
          <ReactECharts
            style={{ width: "100%", height: "100%" }}
            option={options}
            key={props.chartKey}
            notMerge={true}
          />
          </Spin>
        
        </div>
      </div>
    </div>
  );
};

export default ChartAnalysis;
