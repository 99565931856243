import React, { useState } from 'react';
import { Radio } from "antd"
import Style from './SubjectCreditBarChart.module.scss'
import ReactEcharts from 'echarts-for-react'
import { useSearchParams } from 'react-router-dom'
import CustomModal from '../../../components/CustomModal'
import Tip from '../../../assets/icons/tip.png'

export default function SubjectCreditBarChart(props) {
  const {basicData,newData=[]} = props
  const [searchParams] = useSearchParams(); // 获取查询参数
  const id = searchParams.get("id"); // 获取名为 'url' 的查询参数
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [radioActive, setRadioActive] = useState("default");

  const xAxisData = ["aaa1", "aaa2", "aaa3", "aaa4", "aaa5", "aa+1", "aa+2", "aa", "aa-", "a+", "a", "a-", "bbb+", "bbb", "bbb-", "bb+", "bb", "bb-", "b+", "b", "b-", "ccc"]
  const xFAxisData = ["aaa1", "aaa2", "aaa3", "aaa4", "aaa5", "aa+","aa", "aa-", "a+", "a", "a-", "bbb+", "bbb", "bbb-", "bb+", "bb", "bb-", "b+", "b", "b-", "ccc"]
  const getChartOptions = () => {
    let options = {
      title: {
        text: '约 4500 家样本企业潜在主体信用质量分布',
        left: "center",
        bottom: 0,
        textStyle: {
          fontSize: 16,
          color: '#1D2129'
        }
      },
      tooltip: {},
      legend: {
        show: true
      },
      grid: {
        left: "50px", right: "50px"
      },
      xAxis: {
        data: getXData(),
      },
      yAxis: {
        type: "value",
        // name: '企业数量',
        // nameLocation: 'end',
        // nameGap: 30,
      },
      series: getSeries()
    }
    return options;
  }
  const getSeries = () => {
    let detailArr = detailData(newData)
    const baseData = [
      {
        type: "bar",
        data: detailArr,
      },
    ]
    const findIndex = xAxisData.findIndex(d=> d==basicData?.doc?.icrSub)
    if(findIndex!==-1){
      baseData[0].data[findIndex] = {value: baseData[0].data[findIndex], itemStyle: {color: " #33D695"}}
    }
    return baseData
  }
  const detailData = (item) => {
    const map1 = new Map(item?.map(item => [item.quality, item.qualityNum]));
    let result = []
    if(props.isFIType) {
     result = xFAxisData.map(type => type == "aa+" ? map1.get("aa+1") : map1.get(type) !== undefined ? map1.get(type) : 0);
    }else {
     result = xAxisData.map(type => map1.get(type) !== undefined ? map1.get(type) : 0);
    }
    return result
  }
  const getXData = () => {
    if(props.isFIType) {
      return xFAxisData
    }else {
      return xAxisData
    }
    
  }
  const radioChange = (value) => {
    let info = props.isFIType ? '金融机构' : '工商企业'
    if (value === "large") {
      setRadioActive("large")
      props.changeSubjectInfo(true,info)

    } else {
      setRadioActive("default")
      props.changeSubjectInfo(false,info)
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false);
    setRadioActive("default")
  };
  const handleOk = () => {
    handleCancel()
  };

  return (
    <div className={Style['subjectCreditBarChart']}>
      <div className={Style['head']}>
        <div>
          <Radio.Group style={{width: "146px",height: "32px",padding: "2px",background: "#E7E7E7",boxSizing:"border-box",borderRadius:"3px"}} defaultValue={"default"} value={radioActive} buttonStyle="solid" onChange={(e) => radioChange(e.target.value)}>
            <Radio.Button style={{width:"71px",height:"28px",borderRadius:"3px",paddingInline:"unset",textAlign:"center"}} value="default">全行业</Radio.Button>
            <Radio.Button style={{width:"71px",height:"28px",borderRadius:"3px",paddingInline:"unset",textAlign:"center"}} value="large">本行业</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className={Style['info']}>
        <div className={Style.name}>企业数量</div>
        {/* <div className={Style.symbol}>
          <div className={Style.signBox}></div>
          <div>企业数量</div>
        </div> */}
      </div>
      <ReactEcharts
        option={getChartOptions()}      // option：图表配置项
        notMerge={true}
        lazyUpdate={true}
        style={{ height: '400px' }}
      />
      {isModalOpen &&
        <CustomModal handleCancel={handleCancel} handleOk={handleOk} isModalOpen={isModalOpen} wechatQRCode={true} />
      }
    </div>
  )
}
