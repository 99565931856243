import React, {useEffect, useState} from 'react'
import {Anchor,Menu} from 'antd'
import style from './index.module.scss'
import './defaultStyle.scss'

const TabContentItem = (props) => {
  const {data={}, menus=[], defaultActiveKey, defaultOpenSubKey} = props
  const [current, setCurrent] = useState('')
  const [menuList, setMenuList] = useState(menus) //菜单项
  const [openStatus, setOpenStatus] = useState(true) //是否展示菜单
  const [menuActiveKey, setMenuActiveKey] = useState(defaultActiveKey||'') //菜单选中
  const [topOffset, setTopOffset] = useState(0) //距离顶部偏差距离
  const containerWidth = 177
  /**
   * @description: 点击菜单事件
   * @param {*} event {key}
   */  
  const menuClick = (event, key) => {
    event.preventDefault()
  }
  /**
   * @description: 点击展开收起按钮
   */  
  const handleMenuClick = () => {
    setOpenStatus(!openStatus)
    props?.handleMenuWidth(!openStatus?containerWidth:15)
  }
  useEffect(()=>{
    window.addEventListener('scroll',scrollBody)
    props?.handleMenuWidth(openStatus?containerWidth:15)
  },[])
  /**
   * @description: 监听页面滚动
   */  
  const scrollBody = () => {
    const bodyContainer = document.getElementById('bodyContent_wrapper')
    if(bodyContainer){
      if(window.scrollY>bodyContainer.offsetTop){
        setTopOffset(window.scrollY-bodyContainer.offsetTop+64)
      } else setTopOffset(0)
    }
  }
  return <div className={style.tabContentItem_container}>
    <div style={{width:openStatus?containerWidth:15}}></div>
    <div className={`${style.tabContentItem_wrapper} ${openStatus?style.openMenu:''}`} style={{top:topOffset}}>
      { openStatus
          ?<div className={`${style.tabContentItem_menu} menuWrapper`}>
            {/* <Menu onClick={menuClick} selectedKeys={menuActiveKey} defaultSelectedKeys={[defaultActiveKey]} 
              style={{border: 0}} defaultOpenKeys={defaultOpenSubKey}
              theme="light" mode="inline" items={menuList} /> */}
            <Anchor style={{padding:"0px"}} affix={false} items={menuList} targetOffset={70} onClick={menuClick}/>
          </div>
          :null
      }
      <div className={style.tabContentItem_contractBtn} onClick={handleMenuClick}>{openStatus?'<':'>'}</div>
    </div>
  </div>
}

export default TabContentItem