import React, {useEffect, useState} from 'react'
import {Table,Radio} from 'antd'
import api from '@/api'
import {isArray, isObject} from '@/utils'
import FinancialStatements from '../financialStatements'
import Style from './index.module.scss'

/**
 * @description: 
 * @param {*} props.title 标题
 * @param {*} props.type 引用样式类型 detailStyle:详情样式  industryStyle:行业样式
 * @param {*} props.data 数据信息
 * @return {*}
 */
const FinancialAnalusis = (props) => {
  const {basicData={},id} = props
  const [dataSource, setDataSource] = useState({})
  const [radioActive, setRadioActive] = useState("default")
  
  useEffect(() => {
    getCompanyFinance()
  }, [])
  const getCompanyFinance = async () => {
    let result = await api.getFinanceDetail({id})
    if(result?.code == 200){
      setDataSource(result.data)
    }
  }
  const getKeyIndicatorsTableData = (data) => {
    if (data?.doc?.entityFinancialData){
      if (isArray(data?.doc?.entityFinancialData)&&data.doc.entityFinancialData.length > 0) {
        const result = [];
        const indicators = new Set(); // 用于去重指标名称  
        // 遍历数据，收集所有独特的指标名称  
        data.doc.entityFinancialData.forEach(item => {
          item.financialIndicator.forEach(indicator => {
            indicators.add(indicator.indicatorName);
          });
        });
        // 转换为所需格式  
        indicators.forEach(indicatorName => {
          const entry = { name: indicatorName };
          data.doc.entityFinancialData.forEach(item => {
            const value = item.financialIndicator.find(i => i.indicatorName === indicatorName)?.indicatorValue;
            if (value) {
              entry[item.年份] = value;
            }
          });
          result.push(entry);
        });
        return result;
      } else if(isObject(data?.doc?.entityFinancialData)&&data.doc.entityFinancialData.financialIndicator.length>0){
        const result = [];
        data.doc.entityFinancialData.financialIndicator.map(item=>{
          if(item.indicatorValue) {
            result.push({name: item.indicatorName, [data.doc.entityFinancialData['财务指标年度']||'-']:item.indicatorValue})
          }
        })
        return result
      }
    }
  }
  // 关键指标数据处理
  const getKeyIndicatorscolumns = (data) => {
    let columns = [
      {
        title: ' ',
        dataIndex: 'name',
        key: 'name',
        width: 200
      },
      // {
      //   title: <div className='tableTitle'>2017年&nbsp;&nbsp; 较上年+20% <img src={UpPng} alt="" /></div>,
      //   dataIndex: '2017',
      //   key: '2017',
      // },
    ]
    if(data?.doc?.entityFinancialData){
      if (isArray(data?.doc?.entityFinancialData)&&data.doc.entityFinancialData.length > 0) {
        let carr = []
        data.doc.entityFinancialData.map(item => {
          carr.push({
            title: item['年份'],
            dataIndex: item['年份'],
            key: item['年份'],
            width: 200
          })
        })
        let rearr = carr.reverse()
        columns = [...columns,...rearr]
      } else if(isObject(data?.doc?.entityFinancialData)&&data.doc.entityFinancialData.financialIndicator.length>0){
        columns.push({
          title: data.doc.entityFinancialData['财务指标年度']||'-',
          dataIndex: data.doc.entityFinancialData['财务指标年度']||'-',
          key: data.doc.entityFinancialData['财务指标年度']||'-',
          width: 200
        })
      }
    }
    return columns
  }
  // console.log(basicData,88998989)
  return <div className={Style.financialAnalusis_container}>
    <Radio.Group style={{width: "146px",height: "32px",padding: "2px",background: "#E7E7E7",boxSizing:"border-box",borderRadius:"3px"}} defaultValue={"default"} value={radioActive} buttonStyle="solid" onChange={(e) => setRadioActive(e.target.value)}>
      <Radio.Button style={{width:"71px",height:"28px",borderRadius:"3px",paddingInline:"unset",textAlign:"center"}} value="default">关键指标</Radio.Button>
      <Radio.Button style={{width:"71px",height:"28px",borderRadius:"3px",paddingInline:"unset",textAlign:"center"}} value="large">财务报表</Radio.Button>
    </Radio.Group>
    <div className={Style.financialAnalusis_table}>
      { radioActive=='default'
          ?<Table
            size="middle"
            // rowClassName={(record, index) => index % 2 === 0 ? Style["even"] : Style["odd"]}
            bordered
            rowKey={(record, index) => index}
            columns={getKeyIndicatorscolumns(basicData)}
            dataSource={getKeyIndicatorsTableData(basicData)}
            pagination={false}
          />
          :<FinancialStatements dataSource={dataSource} />
      }
    </div>
  </div>
}

export default FinancialAnalusis